<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation>
			<ti-card :icon="$icons.target">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Benchmark Question</template>
				<template #title-actions-shown>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this benchmark_question?" button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>

				<ti-form-layout>
					<template #sidebar>

						<v-col cols="12">
							<v-select v-model="benchmark_question.answer" :items="options" item-value="id" item-text="text" label="Correct answer"></v-select>
						</v-col>

						<v-col cols="12">
							<v-select
								v-model="benchmark_question.topic_id"
								:items="benchmark_topics"
								label="Topic"
								item-text="name"
								item-value="id"
							></v-select>
						</v-col>

						<v-col cols="12">
							<v-slider v-model="benchmark_question.weight" label="Weight" hint="Weighting" min="0" max="10" thumb-color="primary" thumb-label="always" step="1"></v-slider>
						</v-col>

						<v-col cols="12">
							<ti-expertise-slider v-model="benchmark_question.expertise" step="50"></ti-expertise-slider>
						</v-col>

					</template>
					<template>

						<v-col cols="12">
							<v-text-field v-model="benchmark_question.question" :rules="validations.name" label="Question"></v-text-field>
						</v-col>

						<v-col cols="12">
							<h4 class="text-h4">Options</h4>
						</v-col>

						<v-col cols="12">
							<v-text-field v-model="benchmark_question.option_1" label="Option 1"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field v-model="benchmark_question.option_2" label="Option 2"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field v-model="benchmark_question.option_3" label="Option 3"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field v-model="benchmark_question.option_4" label="Option 4"></v-text-field>
						</v-col>
					</template>
				</ti-form-layout>

			</ti-card>

		</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import _forEach from 'lodash/forEach'
import {sync} from "vuex-pathify";
import models from '@/models'

export default {

	name: "BenchmarkQuestionsEdit",
	props: ['id'],
	computed: {
		benchmark_question: sync('benchmark_questions/benchmark_question'),
		topics: sync('topics/topics'),
		benchmark_topics(){
			return this.topics.filter((topic) => topic.use_for_benchmark === true)
		},
		isEdit() {
			return this.$route.meta.edit
		},
		options() {
			return [
				{id: 1, text: this.benchmark_question?.option_1},
				{id: 2, text: this.benchmark_question?.option_2},
				{id: 3, text: this.benchmark_question?.option_3},
				{id: 4, text: this.benchmark_question?.option_4},
			]
		},
	},
	data: () => ({
		valid: false,
		validations: {
			name: validationRules('Name', ['required', 'minLength:2']),
		},
		levels: [
			{}
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.$store.set('benchmark_questions/getBenchmark_question!', this.id)
			} else {
				this.benchmark_question = models.BenchmarkQuestion()
			}
			this.$store.set('topics/getTopics!', null)
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.benchmark_questions.update(this.id, this.benchmark_question)
						.then(response => {
							this.$toast.add('Benchmark Question updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'BenchmarkQuestions'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				} else {
					this.$api.benchmark_questions.create(this.benchmark_question)
						.then(response => {
							this.$toast.add('Benchmark Question created successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'BenchmarkQuestions'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			alert('delete method not yet implemented.')
		},
	}
}
</script>